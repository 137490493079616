@import "variables";
@import "./theme.scss";

@import "/node_modules/primeicons/primeicons.css";
@import "/node_modules/primeflex/primeflex.css";
@import "components/titles";
@import "components/input";
@import "components/tooltip";
@import "components/table";
@import "components/link";
@import "components/card";

body {
  font-family: var(--font-family);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $bodyfont;
}

body {
  background: #f3f3f3;
}

.p-overlay {
  width: 100%;
}
.p-fileupload-row > div:nth-child(1)::after {
  content: '\f6dd';
  font-weight: 900;
  font-size: 35px;
  font-family: "Font Awesome 6 Free", sans-serif;
}


.tabview-custom {
  //background: red;
}

.text-as-sentence {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.p-tabview-nav-link {
  width: max-content !important;
}

th .p-button {
  padding: 0 !important;
}

.p-input-icon-left .pi-search {
  line-height: 100% !important;
}

.p-input-icon-left>i, .p-input-icon-left>.p-icon-wrapper, .p-input-icon-right>i, .p-input-icon-right>.p-icon-wrapper {
  top: 15px !important;
}

.p-dropdown-clear-icon  {
  top: 15px !important;
}

.text-decoration-underline {
  text-decoration: underline;
  cursor: pointer;
}

// CHIPS
.chip-invalid {
  background: $danger !important;
  font-size: 12px;
  color: white;
}
.chip-valid {
  background: $success !important;
  font-size: 12px;
  color: white;
}

.inside-header > div{
  background: white !important;
  //margin-bottom: 16px;
}
